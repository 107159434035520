import React from "react";

const RecommendedQuestoins = [
  "Why did you buy twitter?",
  "What's your next plan",
  "When are we getting to Mars?",

];

function AskQuestion() {
  return (
    <div className="rounded-lg shadow mb-5   bg-white border  border-blue-300 p-4">
      <div className="relative flex items-center  mb-4">
        <input
         
          type="textarea"
          className="peer relative h-10 w-full rounded-md  pl-4 pr-20 font-thin outline-none drop-shadow-md transition-all duration-200 ease-in-out bg-white focus:drop-shadow-lg"
          placeholder="Ask Any Question"
        />
        <button className="absolute right-2 h-7 w-16 rounded-md bg-blue-400 text-xs font-semibold text-white transition-all duration-200 ease-in-out group-focus-within:bg-blue-400 group-focus-within:hover:bg-blue-500">
          Submit
        </button>
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-3">
        {RecommendedQuestoins.map((question, index) => {
          return (
            
            <button
              type="button"
              className="text-start border border-gray-200 !bg-gray-100 text-gray-700 hover:text-blue-500 rounded-md px-4 py-2  cursor-pointer transition ease-in-out duration-300  select-none hover:!bg-blue-200 focus:outline-none focus:shadow-outline"
            >
              {question}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default AskQuestion;

import React from "react";

function QuickCreate() {
  return (
    <div>

      <button className="text-xl leading-5 inline-block px-4 hover:px-5 pt-2 pb-3  text-blue-600 bg-blue-200 rounded-md mb-7 ease-in-out duration-300 ">Quick Create</button>


      <div>
        <div className="flex  gap-3 mb-4">
      
            <img
              src="https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b"
              className=" w-24 "
              alt="...."
            />

          <div className="flex-auto">
            <div className="group relative ">
              <label
                for="1"
                className="text-2xl block w-full pb-1 font-medium  transition-all duration-200 ease-in-out group-focus-within:text-blue-400"
              >
                Twitter
              </label>
              <input
                id="1"
                type="text"
                className=" h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-1 focus:ring-blue-400 border-gray-800 shadow"
                placeholder="Text Form"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="group ">
            <div className="relative flex items-center">
              <textarea
                id="#"
                type="textarea"
                className="peer relative h-20 w-full rounded-md bg-gray-50 px-4 py-3 shadow-md font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg"
                placeholder="Multi-line text form"
              >

              </textarea>
              <button className="absolute right-2 bottom-2 h-7 w-16 rounded-md bg-blue-400 text-xs font-semibold text-white transition-all duration-200 ease-in-out group-focus-within:bg-blue-400 group-focus-within:hover:bg-blue-600">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickCreate;

import AskQuestion from "./Components/AskQuestion";
import Chat from "./Components/Chat";
// import Featured from "./Components/Featured";
import QuickCreate from "./Components/QuickCreate";
import Select from "./Components/Select";

function App() {
  return (
    <div className="bg-gray-50  py-4 ">
      <div className="container mx-auto min-h-full px-2 ">
        <Chat />
        <AskQuestion />
        <div className="flex gap-5 lg:gap-3 flex-wrap lg:flex-nowrap bg-white rounded-lg shadow  p-4 border  border-blue-300">
          <div className="flex-none w-full lg:w-64 ">
            <QuickCreate />
          </div>
          {/* <div className=" w-full flex-1 lg:border-l-2 lg:ps-3 border-gray-100">
            <Featured />
          </div> */}
          <div className="flex-1 w-64 lg:border-l-2 lg:ps-3 border-gray-100">
            <Select />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import LoginForm from "./LoginForm";
import ChatMessageBox from "./ChatMessageBox";

function Chat() {
  return (
    <div className="rounded-lg shadow mb-5   bg-white border  border-blue-300">
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="flex-1 lg:w-64 shadow-sm rounded-lg  p-4 pe-2">
          <div className="flex flex-wrap gap-5 items-center mb-7 pe-2 ">
            <button className="text-xl leading-5 inline-block px-4 hover:px-5 pt-2 pb-3  text-blue-600 bg-blue-200 rounded-md ease-in-out duration-300 ">
              Chat
            </button>
            <div>
              <p className="text-red-600">Disclaimer: All interactions are fake</p>
            </div>
          </div>
          <ChatMessageBox/>
        </div>
        <div className="flex-none lg:w-64 p-4 lg:border-l-2 border-gray-100">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default Chat;

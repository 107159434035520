import React  from "react";
import Card from "./Card";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Mousewheel, Pagination } from "swiper";

const SelctCards = [
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
  {
    Title: "NASA",
    ImageSrc: "https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b",
  },
];

function Select() {
  return (
    <div>
      <button className="text-xl leading-5 inline-block px-4 hover:px-5 pt-2 pb-3  text-blue-600 bg-blue-200 rounded-md mb-7 ease-in-out duration-300 ">Select</button>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={16}
        mousewheel={true}
        pagination={{
          clickable: true,
          el: ".ts-selectPagination",
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper "
      >
        {SelctCards.map((card, index) => {
          return (
            <SwiperSlide style={{ width: "161px" }} key={index}>
              <Card Title={card.Title} ImageSrc={card.ImageSrc} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="flex justify-center">
        <div className="ts-selectPagination flex !justify-center gap-1"></div>
      </div>
    </div>
  );
}

export default Select;

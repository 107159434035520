import React from "react";

function LoginForm() {
  return (
    <div>
      <div className="mb-10">
        <img
          src="https://img.freepik.com/free-photo/skull-with-many-different-parts-it_698780-1005.jpg?w=826&t=st=1685976357~exp=1685976957~hmac=9dfba1bbcc9d6f49d2eb360c29ac4780f2aa5b96c98ab8f790816b2b9591312b"
          className="ts-card-img"
          alt="...."
        />
        <p className="text-2xl">Elon Musk</p>
      </div>

      <h2 className="text-2xl mb-4 ">Login</h2>
      <form action="#">
        <div className="relative flex items-center  mb-4">
          <input
            
            type="text"
            className="relative h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-md transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg autofill:bg-transparent"
            placeholder="Text form"
          />
        </div>
        <div className="relative flex items-center  mb-4">
          <input
        
            type="password"
            className="relative h-10 w-full rounded-md bg-gray-50 px-4 font-thin outline-none drop-shadow-md transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg autofill:bg-transparent"
            placeholder="Password Form"
          />
        </div>
        <button className="text-lg  py-1 px-5 rounded-md bg-blue-400   text-white transition-all duration-200 ease-in-out group-focus-within:bg-blue-400 group-focus-within:hover:bg-blue-600">
          Submit
        </button>
      </form>
    </div>
  );
}

export default LoginForm;

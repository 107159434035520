import React from 'react'

const Card = ({Title , ImageSrc}) => {
  return (
    <div className='cursor-pointer'>
          <img src={ImageSrc} className="ts-card-img mb-2" alt="...." />
          <p className="text-2xl">{Title}</p>
    </div>
  )
}

export default Card

import React from "react";

function ChatMessageBox() {
  return (
      <div className="ts-chat-box mx-auto space-y-12 grid grid-cols-1 overflow-y-auto pe-2" >
      <div className="place-self-start text-left">
        <div className="bg-red-50 p-5 rounded-2xl rounded-tl-none">
          Wanted to know if you wanted to get lunch sometime this week?
        </div>
      </div>
      <div className="place-self-end text-right">
        <div className="bg-blue-50 text-blue-900 p-5 rounded-2xl rounded-tr-none">
          Oops! Sorry for the late response!
        </div>
      </div>

      <div className="place-self-start text-left">
        <div className="bg-red-50 p-5 rounded-2xl rounded-tl-none">
          Or next week. I'm also free during the evenings next week.
        </div>
      </div>

      <div className="place-self-end text-right">
        <div className="bg-blue-50 text-blue-900 p-5 rounded-2xl rounded-tr-none">
          I'd love to get lunch sometime next week!
        </div>
      </div>

      <div className="place-self-start text-left">
        <div className="bg-red-50 p-5 rounded-2xl rounded-tl-none">
          Or next week. I'm also free during the evenings next week.
        </div>
      </div>

      <div className="place-self-end text-right">
        <div className="bg-blue-50 text-blue-900 p-5 rounded-2xl rounded-tr-none">
          I'd love to get lunch sometime next week!
        </div>
      </div>
    </div>
  );
}

export default ChatMessageBox;
